/* eslint-disable no-unused-vars */
import {Howl, Howler} from 'howler';
import _ from "lodash";

export default {
    install(Vue) {

        var loaded = false
        var SOUNDS = {};
        var SOUNDKEYS = []; 
        var PlayDefaults = {
            loop: false,
            fadein: false,
        }
        var StopDefaults = {
            fadeout: true,
        }
        var bgMusicLoop = null; // me aseguro que no hayan dos musicas en simultaneo
        
        Vue.prototype.$sfxLoad = function(soundsToLoad = this.$store.state.setup.sounds){
            if(loaded && soundsToLoad == this.$store.state.setup.sounds) return
            loaded = true;

            var newKeys = Object.keys(soundsToLoad)
            let volume = this.$store.getters["space/config"] ? this.$store.getters["space/config"].space.volume / 100 : 1;
            console.log("🎹 Loading", newKeys.length,"sounds")
            newKeys.forEach((soundKey) => {
                const data = soundsToLoad[soundKey]
                if(data == null) return
                // console.log("🎹 Loading", soundKey)
                let sobreescribiendo = typeof(SOUNDS[soundKey]) == "object"
                if(sobreescribiendo){
                    // console.log("🔈 Overriding sound", soundKey)
                    // console.log(SOUNDS[soundKey])
                    // const wasPlaying = SOUNDS[soundKey].isPlaying;
                    // const wasLooping = SOUNDS[soundKey]._loop;
                    // console.log("wasPlaying", wasPlaying, "wasLooping", wasLooping)
                    SOUNDS[soundKey].unload()
                    SOUNDS[soundKey] = new Howl({
                        src: [data.url]
                    });
                    // if(wasPlaying){
                    //     // SOUNDS[soundKey].play()
                    //     SOUNDS[soundKey].volume(Howler.volume())
                    //     SOUNDS[soundKey].loop(wasLooping)
                    // }
                }else{
                    SOUNDS[soundKey] = new Howl({
                        src: [data.url]
                    });                  
                }
                SOUNDS[soundKey].isPlaying = false
                SOUNDS[soundKey].on('stop', function(){
                    if(!SOUNDS[soundKey]._loop){
                        SOUNDS[soundKey].isPlaying = false
                    }
                })
                Howler.volume(volume)
                SOUNDKEYS = _.union(SOUNDKEYS, newKeys);
            })
        }

        Vue.prototype.$sfxPlay = function(key, _settings){
            try{
                if(!SOUNDKEYS.includes(key)){
                    console.warn("🔊 Sonido", key, "no existe o no cargado en backend")
                    return;
                }
                if(typeof SOUNDS[key] == 'undefined') return;
                let settings = {...PlayDefaults, ..._settings}
                console.log("🔊 playing", key, "@ volume", Howler.volume())
                SOUNDS[key].loop(settings.loop)
                SOUNDS[key].volume(Howler.volume())
                SOUNDS[key].seek(0)
                SOUNDS[key].play()
                SOUNDS[key].isPlaying = true
                // console.log("playing", SOUNDS[key].isPlaying)
                if(settings.fadein){
                    SOUNDS[key].fade(0, Howler.volume(), 700)
                }
            }catch(e){
                console.warn("❗️ Error on sfxPlay", e)
            }
        }

        Vue.prototype.$sfxStop = function(key, _settings){
            try{
                if(!SOUNDKEYS.includes(key)){
                    console.warn("🔇 Sonido", key, "no existe o no cargado en backend")
                    return;
                }
                if(typeof SOUNDS[key] == 'undefined') return;
                let settings = {...StopDefaults, ..._settings}
                console.log("🔇 stopping", key)
                if(settings.fadeout){
                    SOUNDS[key].fade(Howler.volume(), 0, 1000)
                    SOUNDS[key].once('fade', function(){
                        SOUNDS[key].pause()
                    });
                }else{
                    SOUNDS[key].pause()
                }
            }catch(e){
                console.warn("❗️ Error on sfxStop", e)
            }
        }

        Vue.prototype.$sfxChangeVolume = function(volume){
            Howler.volume(volume);
            console.log("🔊 Changing Howler volume to", volume)
        }
    },
};