module.exports = {
    RandomNickname
}

function RandomNickname() {
    const rand = Math.round(Math.random() * 99999);
    const plants = [
        "Soy",
        "Rye",
        "Rice",
        "Bean",
        "Lentil",
        "Linen",
        "Porto",
        "Corn",
        "Onion",
        "Garlic",
        "Vetch",
        "Barley",
        "Cotton",
        "Cumin",
        "Peanut",
        "Darling",
        "Parsley",
        "Pepper",
        "Radish",
        "Wheat",
        "Cucumber",
        "Sunflower",
        "Sorghum",
        "Pumpkin",
        "Chickpea",
        "Tomatoes",
        "Crataegus",
    ];
    const moods = [
        "Loud",
        "Wise",
        "Happy",
        "Mellow",
        "Excited",
        "Curious",
        "Radiant",
        "Generous",
        "Magnetic",
        "Likeable",
        "Reliable",
        "Witty",
        "Divine",
        "Lively",
        "Versatile",
        "Amazing",
        "Friendly",
        "Strong",
        "Sincere",
        "Kind",
    ];
    const plant = plants[rand % plants.length];
    const mood = moods[rand % moods.length];
    return mood + " " + plant;
}