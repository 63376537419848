import Vue from "vue";
import { defineAsyncComponent } from 'vue'

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import App from "./App.vue";
// eslint-disable-next-line
import { sync } from "vuex-router-sync";
import router from "./router";
import store from "./store";
const whitelabelConfig = require('@/whitelabel.config');
// const unsync = sync(store, router); // https://github.com/vuejs/vuex-router-sync

import Buefy from "buefy";
// Sistema white label diseñado según esto:
// https://www.nickmcburney.co.uk/blog/vuejs-whitelabel
import "./styles/main.scss";

// Estilos opcionales segun theme
try {
  require('brandVariables/extras/general.scss')
} catch (e){
  // Do something
}

Vue.use(Buefy, {
  // defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
});

// global components
Vue.component("d-icon", defineAsyncComponent(() =>
  import("./components/DouobIcon.vue")
));

Vue.component("UserHeader", defineAsyncComponent(() =>
  import("./components/UserHeader.vue")
));
Vue.component("MenuComponent", defineAsyncComponent(() =>
  import("./components/MenuComponent.vue")
));

Vue.component("PlayerName", defineAsyncComponent(() =>
  import("./components/PlayerName.vue")
));
Vue.component("ProfileEditor", defineAsyncComponent(() =>
  import("./components/account/ProfileEditor.vue")
));

Vue.component("ProfileVerify", defineAsyncComponent(() =>
  import("./components/account/ProfileVerify.vue")
));

Vue.component("PlayerAvatar", defineAsyncComponent(() =>
  import("./components/PlayerAvatar.vue")
));
import AppLogo from "./components/account/AppLogo";
Vue.component("AppLogo", AppLogo);

// import AdminRemote from "./components/AdminRemote.vue";
Vue.component("AdminRemote", defineAsyncComponent(() =>
import("./components/AdminRemote.vue")
));

Vue.mixin({
  methods: {
    // Para usar dentro de un async
    // Genera una pausa en cualquier momento
    // await this.$timeout(4000);
    $timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    $camelToWords(input) {
      // chatgpt
      // Check if the input is not a string or is already in normal text format
      if (typeof input !== 'string' || input === input.toLowerCase()) {
        return input.charAt(0).toUpperCase() + input.slice(1);
      }

      // Convert camel case to pretty text
      const result = input
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before capital letters
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Insert space between consecutive capital letters
        .toLowerCase() // Convert to lowercase

      return result.charAt(0).toUpperCase() + result.slice(1); // Capitalize the first letter
    },
    $getFormattedDate(date, prefomattedDate = false, hideYear = false) {
      // const MONTH_NAMES = [
      //   'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'
      // ];
      const day = date.getDate();
      // const month = MONTH_NAMES[date.getMonth()];
      const month = date.getMonth()+1;
      const year = date.getFullYear();
      const hours = date.getHours();
      let minutes = date.getMinutes();
    
      if (minutes < 10) {
        // Adding leading zero to minutes
        minutes = `0${ minutes }`;
      }
    
      if (prefomattedDate) {
        // Today at 10:20
        // Yesterday at 10:20
        return `${ prefomattedDate } ${ hours }:${ minutes }`;
      }
    
      if (hideYear) {
        // 10. January at 10:20
        return `${ day }/${ month } ${ hours }:${ minutes }`;
      }
    
      // 10. January 2017. at 10:20
      return `${ day }. ${ month } ${ year } | ${ hours }:${ minutes }`;
    },
    $timeAgo(dateParam){
      if (!dateParam) {
        return null;
      }
      const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
      const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
      const today = new Date();
      const yesterday = new Date(today - DAY_IN_MS);
      const seconds = Math.round((today - date) / 1000);
      const minutes = Math.round(seconds / 60);
      const isToday = today.toDateString() === date.toDateString();
      const isYesterday = yesterday.toDateString() === date.toDateString();
      const isThisYear = today.getFullYear() === date.getFullYear();
    
      if (seconds < 5) {
        return 'now';
      } else if (seconds < 60) {
        return `${ seconds } seconds ago`;
      } else if (seconds < 90) {
        return 'about a minute ago';
      } else if (minutes < 60) {
        return `${ minutes } minutes ago`;
      } else if (isToday) {
        return this.$getFormattedDate(date, 'Hoy'); // Today at 10:20
      } else if (isYesterday) {
        return this.$getFormattedDate(date, 'Ayer'); // Yesterday at 10:20
      } else if (isThisYear) {
        return this.$getFormattedDate(date, false, true); // 10. January at 10:20
      }
      return this.$getFormattedDate(date); // 10. January 2017. at 10:20
    }
  },
});

Vue.prototype.$ACCOUNTSTATE = Object.freeze({
  inactive: 0,
  login: 1,
  register: 2,
  verify: 3,
});

// Socket IO: https://github.com/probil/vue-socket.io-extended
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";
// import { createNamespacedHelpers } from "vuex";
const socket = io(process.env.VUE_APP_SOCKET_URL, {
  autoConnect: false,
});
Vue.use(VueSocketIOExt, socket, { store });

Vue.config.productionTip = true;
Vue.prototype.$baseUrl = process.env.VUE_APP_API_BASE;

// i18n locales
import messages from './translation/locales.json';
console.log("*** LOADING translation")
// messages["en"]["account"]["createUser"] = "test"
Vue.prototype.messages = messages;
const i18n = new VueI18n({
  locale: whitelabelConfig.defaultLanguage || 'en',
  fallbackLocale: 'en',
  messages: Vue.prototype.messages,
  // silentTranslationWarn: true
})

// Douob sounds
import DouobSounds from './components/DouobSounds';
Vue.use(DouobSounds)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

// Disable doubletap for zoom in safari
const isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1

if (isSafari) {

  let drags = new Set() //set of all active drags
  document.addEventListener("touchmove", function(event){
    if(!event.isTrusted)return //don't react to fake touches
    Array.from(event.changedTouches).forEach(function(touch){
      drags.add(touch.identifier) //mark this touch as a drag
    })
  })
  document.addEventListener("touchend", function(event){
    if(!event.isTrusted)return
    let isDrag = false
    Array.from(event.changedTouches).forEach(function(touch){
      if(drags.has(touch.identifier)){
        isDrag = true
      }
      drags.delete(touch.identifier) //touch ended, so delete it
    })
    if(!isDrag && document.activeElement == document.body){
      //note that double-tap only happens when the body is active
      event.preventDefault() //don't zoom
      event.stopPropagation() //don't relay event
      event.target.focus() //in case it's an input element
      event.target.click() //in case it has a click handler
      event.target.dispatchEvent(new TouchEvent("touchend",event))
      //dispatch a copy of this event (for other touch handlers)
    }
  })

}