import router from "@/router";
import Vue from "vue";
import axios from "axios";
// eslint-disable-next-line
import _ from "lodash";
import { NotificationProgrammatic as Notification } from "buefy";
import whitelabelConfig from "@/whitelabel.config";

const ACCOUNTSTATES = Object.freeze({
  inactive: 0,
  login: 1,
  register: 2,
  verify: 3,
});

export default {
  namespaced: true,
  state: {
    profile: {
      role: null,
    },
    token: "",
    space: null,
    spaceid: null,
    experienceid: null,
    experience: null,
    editingProfile: false,
    queue: false,
    queueMinutes: null,
    queuePlayers: null,
    accountState: ACCOUNTSTATES.inactive,
    declinedVerification: false,
    players: [],
    achievements: [],
    extraFields: [],
    experienceInviteShow: false,
    experienceInviteData: null,
    menuOffsetTop: 0,
  },
  mutations: {
    async SetUserExtraFields(state, extraFields) {
      state.extraFields = extraFields;
    },
    ForceVerification(state) {
      state.declinedVerification = false;
    },
    DeclineVerification(state) {
      state.declinedVerification = true;
    },
    SetAchievements(state, data) {
      // console.log("Set achievements");
      state.achievements = data;
    },
    ResetPlayerList(state) {
      state.players = [];
    },
    SOCKET_PLAYERLIST(state, data) {
      console.log(
        "🏄🏄 Received player list for space:",
        data.space,
        data.players
      );
      state.players = data.players;
    },
    SOCKET_PLAYERJOINED(state, player) {
      console.log("🏄 Player joined", player);
      // Avoid accidental duplicated
      // TODO REVISAR !!
      if (!state.players.find((x) => x.dbid == player.dbid)) {
        state.players.push(player);
      }
    },
    SOCKET_PLAYERLEFT(state, data) {
      console.log("🏄🔫 Player left", data);
      try {
        const p = state.players.find((x) => x.dbid == data.dbid);
        if (p) {
          const i = state.players.indexOf(p);
          state.players.splice(i, 1);
        }
      } catch (e) {
        console.warn("❗️ No se pudo borrar usuario", e);
      }
    },
    SetAccountState(state, accountState) {
      state.accountState = accountState;
    },
    SetEmail(state, email) {
      console.log("setting email *", email, typeof email);
      localStorage.setItem("user_email", email);
      state.email = email;
      state.profile.email = email;
    },
    SetUser(state, user) {
      if (!user.profile) {
        user.profile = user.user;
        delete user.user;
      }
      console.log("user", user);
      for (const key in user) {
        // Seteo sin sobreescrbir y de manera reactiva
        Vue.set(state, key, user[key]);
      }
    },
    SetToken(state, token) {
      localStorage.setItem("user_token", token);
      state.token = token;
    },
    EditProfile(state, editing = true) {
      state.editingProfile = editing;
    },
    SetSpace(state, data) {
      state.space = data.space;
      state.spaceid = data.spaceid;
    },
    SetExperience(state, data){
      state.experienceid = data.experienceid
      state.experience = data.experience
    },
    SetQueue(state, queue) {
      state.queue = queue;
    },
    SOCKET_QUEUEREMAININGTIME(state, data) {
      console.log("SOCKET_QUEUEREMAININGTIME", data);
      state.queueMinutes = data.minutes;
      state.queuePlayers = data.players;
    },
    SetRole(state, role) {
      // state.role = role;
      state.profile.role = role;
      console.log(state.profile.role);
    },
    async LoadPlayerEmailToken(state) {
      return new Promise((resolve) => {
        if (localStorage.getItem("user_email")) {
          const email = localStorage.getItem("user_email");
          state.email = email;
          state.profile.email = email;
        }
        if (localStorage.getItem("user_token")) {
          state.token = localStorage.getItem("user_token");
        }
        resolve();
      });
    },
    ExperienceInviteHide(ctx) {
      ctx.experienceInviteShow = false
      ctx.experienceInviteData = null
    },
    setMenuOffsetTop(state, data) {
      state.menuOffsetTop = data
    },
  },
  actions: {
    EnterExperience(ctx) {
      ctx.state.experienceInviteShow = false
      ctx.commit("StartLoading", null, { root: true });
      router.push(ctx.state.experienceInviteData.slug)
    },
    NeedsToRegister(ctx) {
      console.log("checking needs to register");
      if (ctx.state.profile.confirmed) return true;
      const achievementsAmount = ctx.state.achievements.length;
      const guestMaxExperiences =
        ctx.rootState.setup.registration.guestMaxExperiences;

      // console.log(achievementsAmount, guestMaxExperiences);
      if (achievementsAmount < guestMaxExperiences) return true;
      console.log("🔐 Verification required");
      // User needs to verify
      ctx.commit("SetAccountState", 3);
    },
    socket_requestUsersVerify(ctx) {
      console.log("requestUsersVerify. Checking needs to register");
      if (ctx.state.profile.confirmed) return true;
      ctx.commit("ForceVerification");
      ctx.commit("SetAccountState", 3);
    },
    socket_connect(ctx) {
      console.log("🦄 connected to socket");
      // USER SERVER LOGIN

      let spaceSlug;
      const nonSocketPaths = ["signup", "login", "duplicate", "inactivity"];

      if (
        typeof ctx.rootState.route.query.redirect != "undefined" &&
        ctx.rootState.route.query.redirect != ""
      ) {
        spaceSlug = ctx.rootState.route.query.redirect;
      } else if (nonSocketPaths.includes(ctx.rootState.route.name)) {
        spaceSlug = "menu";
      } else if (ctx.rootState.route.path != "/") {
        spaceSlug = ctx.rootState.route.path.replace("/", "");
      } else {
        spaceSlug = "menu";
      }
      if(spaceSlug == "agenda"){
        spaceSlug = "menu"
      }

      // const spaceSlug =
      //   spaceSlugCases[ctx.rootState.route.name] != undefined
      //     ? spaceSlugCases[ctx.rootState.route.name]
      //     : ctx.rootState.route.path.replace("/", "");
      console.log("Joining space slug", {
        dbid: ctx.state.profile.id,
        spaceSlug,
      });

      // console.log("player:JoinServer", spaceSlug);
      Vue.prototype.$socket.client.emit("player:JoinServer", {
        dbid: ctx.state.profile.id,
        spaceSlug,
      });
    },

    async CreateGuestUser(ctx, { username, color, avatar }) {
      console.log("Creating guest user");
      ctx.commit("StartLoading", null, { root: true });
      const randId = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5);
      const email = randId + "@default.com";
      try {
        await axios.post(process.env.VUE_APP_API_BASE + "/api/users", {
          email,
          username,
          password: whitelabelConfig.defaultPassword || "default01",
          color,
          avatar,
        });
        ctx.commit("SetEmail", email);
        // await ctx.commit("SetAccountState", 0); // nada
        await ctx.dispatch("AuthPlayer", { email });
        // ctx.commit("StopLoading", null, { root: true });
        Vue.prototype.$socket.client.open();
      } catch (err) {
        Notification.open({
          message: Vue.prototype.i18n.t('notifications.userNameInUse'),
          type: "is-danger",
        });
        // play sound
        ctx.commit("StopLoading", null, { root: true });
        Vue.prototype.$sfxPlay('negativenotification')
      }
    },
    JoinSpaceAttempt(ctx, spaceSlug) {
      ctx.commit("StartLoading", null, { root: true });
      Vue.prototype.$socket.client.emit("joinSpaceAttempt", { spaceSlug });
    },
    socket_dequeued(ctx, data) {
      console.log("dequeued", data);
      if (data.ids.includes(ctx.state.profile.id)) {
        ctx.commit("SetQueue", false);
      }
    },
    async socket_joinSpace(ctx, newSpace) {
      console.log("🦑 SOCKET_JOINSPACE", newSpace);
      ctx.commit("ResetPlayerList"); // Reseteo la playerlist
      ctx.commit("StopLoading", null, { root: true });
      
      ctx.commit("SetExperience", newSpace);
      ctx.commit("SetSpace", newSpace);
      ctx.commit("SetQueue", newSpace.queue);

      if(ctx.rootState.route.path != "/agenda"){
        const goto = newSpace.space == "menu" ? "/" : newSpace.space;
        const currpath =
          ctx.rootState.route.path == "/"
            ? "/"
            : ctx.rootState.route.path.replace("/", ""); // TODO probar esto ctx.rootGetters.getRoute.params.slug;
        if (currpath != goto) {
          router.push({ path: goto });
        }
      }


      // if (newSpace.space == "menu") {
      //   await ctx.dispatch("GetScore"); // esto debería ir en el componente menu y renombrarlo getachievements
      // }
    },
    socket_notification(ctx, data) {
      console.log("Notification", data.msg);
      Notification.open({
        type: data.type,
        message: Vue.prototype.i18n.t('notifications.' + data.msg),
      });
      // play sound
      Vue.prototype.$sfxPlay('atentionnotification')
    },
    socket_invitationToSpace(ctx, data) {
      // console.log("socket_invitationToSpace", data)
      // console.log(ctx.getters.hasAdminAuthority)
      // console.log(data.slug, ctx.state.space)
      if (ctx.getters.hasAdminAuthority) {
        // No les muestro la notificacion a los admins
        return;
      }
      if (data.slug == ctx.state.space) {
        // no lo muestro si ya está en ese space
        return;
      }
      if (data.slug == "/" && ctx.state.space == "menu") {
        // caso especial que ya esté en el menu
        return
      }

      if (data.force) {
        // PUSH INVITE
        // Notitification
        ctx.state.experienceInviteShow = false
        ctx.commit("StartLoading", null, { root: true });
        router.push(data.slug)
        Notification.open({
          type: "is-primary",
          message: Vue.prototype.i18n.t('notifications.leaderPushedYou', [data.leader, data.title])
        })
        // Play sound
        Vue.prototype.$sfxPlay('invitepushtoexperience')
      } else {
        // Invititation received
        // Show modal
        data.text = Vue.prototype.i18n.t('notifications.leaderIsInvitingYou', [data.leader, data.title]);
        ctx.state.experienceInviteData = data;
        ctx.state.experienceInviteShow = true;
        Vue.prototype.$sfxPlay('invitepushtoexperience')
      }
    },
    async GetPlayerRole(ctx, email) {
      console.log("EMAIL", email);
      return new Promise((resolve, reject) => {
        if (email == "") {
          reject();
        }
        axios
          .get(process.env.VUE_APP_API_BASE + "/api/getrole", {
            params: { email },
          })
          .then((res) => {
            console.log("Get role res", res)
            if (res.status == 200) {
              ctx.commit("SetRole", res.data.role);
              resolve();
            } else {
              console.log("Error getting player role", res);
              Notification.open({
                message: Vue.prototype.i18n.t('notifications.userNotFound'),
                type: "is-danger",
              });
              Vue.prototype.$sfxPlay('negativenotification')
              ctx.commit("StopLoading", null, { root: true });
              reject();
            }
          })
          .catch((err) => {
            Notification.open({
              message: err.response.data.error.message,
              type: "is-danger",
            });
            Vue.prototype.$sfxPlay('negativenotification')
            setTimeout(() => {
              // Ultimo recurso
              ctx.dispatch("logout");
            }, 1000);
          });
      });
    },
    async GetPlayer(ctx) {
      return new Promise((resolve, reject) => {
        if (!ctx.state.token) {
          reject();
        }
        axios
          .get(process.env.VUE_APP_API_BASE + "/api/users/me", {
            headers: { Authorization: "JWT " + ctx.state.token },
          })
          .then((res) => {
            if (!res.data.user) {
              // Usuario no encontrado o JWT expirado
              console.warn("Usuario no encontrado o JWT expirado");
              console.log(ctx);
              // Ultimo recurso (a revisar)
              ctx.dispatch("logout");
              reject();
            }
            ctx.commit("SetUser", { profile: res.data.user });
            resolve();
          })
          .catch((err) => {
            console.warn("Error en /me", err);
          });
      });
    },
    async AuthPlayer(ctx, data) {
      return new Promise((resolve, reject) => {
        try {
          const email = data.email;
          const password =
            data.password == "" || data.password == undefined
              ? (whitelabelConfig.defaultPassword || "default01")
              : data.password;
          console.log("authenticating", email, password);
          // TODO chequear si necesita ingresar contraseña (admin)
          if (email != undefined && email != "") {
            axios
              .post(process.env.VUE_APP_API_BASE + "/api/users/login/", {
                email,
                password,
              })
              .then(({ data }) => {
                if (data.message == "Auth Passed") {
                  console.log("PLAYER AUTHENTICATED", data);
                  ctx.commit("SetToken", data.token);
                  // TODO guardar data.exp que es el timestamp en el que vence el token
                  ctx.commit("SetUser", data);
                  resolve();
                } else {
                  console.log("USER AUTH FAILED"); // TODO
                }
              })
              .catch((err) => {
                console.log("auth err", err.response);
                reject(err.response.data);
              });
          } else {
            reject();
          }
        } catch (e) {
          console.log("❗️ Error on Login Player", e);
        }
      });
    },
    async RefreshToken(ctx) {
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}/api/users/refresh-token`,
          {},
          {
            headers: {
              Authorization: "JWT " + ctx.state.token,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            console.log("🗝 token refreshed", res.data.refreshedToken);
            ctx.commit("SetToken", res.data.refreshedToken);
          }
        });
    },
    async GetAchievements(ctx) {
      // http://localhost:3000/api/achievements?depth=0&where[user][equals]=62cd82b58027b1a3940b9a2a
      await axios
        .get(
          `${process.env.VUE_APP_API_BASE}/api/achievements?depth=0&limit=999&where[user][equals]=${ctx.state.profile.id}`,
          {
            headers: {
              Authorization: "JWT " + ctx.state.token,
            },
          }
        )
        .then((res) => {
          console.log("🎖 achievemnets", res.data.docs);
          ctx.commit("SetAchievements", res.data.docs);
        });
    },
    logout(ctx) {
      console.log("logout");
      localStorage.clear();
      localStorage.setItem("userClosedSession", 1)
      ctx.commit("StartLoading", null, { root: true });
      location.reload();
    },
    SendReaction(ctx, data) {

      let type = data.type ? data.type : 'emoji'
      Vue.prototype.$socket.client.emit("SendReaction", {
        reaction: data.reaction,
        category: data.category,
        type,
        experience: ctx.state.experienceid,
        space: ctx.state.spaceid,
        color: data.color,
      });
      console.log(`Sending reaction ${data.type}: ${data.reaction}`);
    },
  },
  getters: {
    ColorSaturation() {
      if (whitelabelConfig.profileColor && whitelabelConfig.profileColor.saturation) {
        return whitelabelConfig.profileColor.saturation;
      }
      return 60
    },
    ColorBrightness() {
      if (whitelabelConfig.profileColor && whitelabelConfig.profileColor.brightness) {
        return whitelabelConfig.profileColor.brightness;
      }
      return 100
    },
    hasAdminAuthority(state) {
      return state.profile.role == "tour-leader";
    },
  },
};
