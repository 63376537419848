<template>
  <div class="is-flex is-justify-content-center">
    <img class="logo" :src="src" />
  </div>
</template>

<script>
export default {
  computed: {
    src() {
      if (!this.$store.state.setup.appLogo) {
        return "/default-header.png"
      }
      return this.$store.state.setup.appLogo.sizes.mobile.url || this.$store.state.setup.appLogo.url
    }
  }
};
</script>
<style scoped>
img {
  max-height: 80px;
  max-width: clamp(200px,450px, 100%);
}
</style>
