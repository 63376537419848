import Vue from "vue";
import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    experiences: [],
    spaces: [],
    playlists: [],
    showRemote: false,
    players: [],
    defaultHostControl: false

  }),
  mutations: {
    GetRawData(state) {
      // TODO Check si está ok el depth 0
      console.log("🍖 Getting raw data for admin");
      const spaces = axios.get(
        process.env.VUE_APP_API_BASE + "/api/spaces?depth=0&limit=999"
      );
      const exp = axios.get(
        process.env.VUE_APP_API_BASE + "/api/experiences?depth=0&limit=999"
      );
      const play = axios.get(
        process.env.VUE_APP_API_BASE + "/api/playlists?depth=0&limit=999"
      );
      axios
        .all([spaces, exp, play])
        .then(
          axios.spread((...responses) => {
            state.spaces = responses[0].data.docs;
            state.experiences = responses[1].data.docs;
            state.playlists = responses[2].data.docs;
          })
        )
        .catch((err) => {
          console.warn("❗️ Error downloading raw data", err);
        });
    },
    showRemote(state, show) {
      state.showRemote = show;
    },
    setDefaultHostControl(state, data){
        state.defaultHostControl = data
    } 
    ,
    SOCKET_PLAYERSSTATE(state, data) {
      state.players = data.players;
    },
    SOCKET_PLAYERJOINED(state, player) {
      // Avoid accidental duplicated
      // TODO REVISAR !!
      console.log("Player joined", player);
      const p = state.players.find((x) => x.dbid == player.dbid);
      if (!p) {
        state.players.push(player);
      } else {
        p.queue = player.queue;
        p.space = player.space;
      }
    },
    SOCKET_PLAYERLEFT(state, data) {
      try {
        const p = state.players.find((x) => x.dbid == data.dbid);
        if (p) {
          const i = state.players.indexOf(p);
          state.players.splice(i, 1);
        }
      } catch (e) {
        console.warn("❗️ No se pudo borrar usuario", e);
      }
    },
    SOCKET_DEQUEUED(state, data) {
      console.log("dequed", data);
      state.players
        .filter((x) => data.ids.includes(x.dbid))
        .map((x) => (x.queue = false));
    },
    SOCKET_PLAYEREDITED(state, changes) {
      console.log("Player changed profile");
      const player = state.players.find((x) => x.dbid == changes.dbid);
      if (player) {
        for (const key in changes) {
          Vue.set(player, key, changes[key]);
        }
      }
    },
    SOCKET_EXPERIENCELISTCHANGED(state) {
      // La lista de experiencias cambio, recargo la lista
      console.log("Updating experience list")
      axios.get(process.env.VUE_APP_API_BASE + "/api/experiences?depth=0&limit=999")
        .then((res) => {
          state.experiences = res.data.docs;
        })
    },
  },
  actions: {
    socket_setupEdited(ctx) {
      ctx.commit("GetRawData");
    },
  },
  getters: {
    // PlayersInSpace(state, spaceSlug: string){
    //   console.log(spaceSlug)
    //   return state.players.filter((x: { spaceSlug: string; }) => x.spaceSlug == spaceSlug);
    // }
  },
};
